import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import ProductList from './product_list.vue';
import correlationList from './correlation_list.vue';

formCreate.component('ProductList', ProductList);
formCreate.component('correlationList', correlationList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'tpm_bottle_opening_scanning_code_form',
    };
  },
  methods: {

    // 设置rule
    setRule(item) {
      const v = item;
      console.log(v);
      if (v.field === 'productNameGift') { // 选择产品
        v.props = {
          ...v.props,
          params: {
            functionCode: 'product_list',
            paramData: {
              enableStatus: '009',
              isShelf: 'Y',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'productReqVos') { // 关联产品
        v.props = {
          ...v.props,
          params: {
            functionCode: 'correlation_list',
            paramData: {
              enableStatus: '009',
              isShelf: 'Y',
            },
          },
          getValue: (field) => this.getFieldValue(field),
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit') {
        this.disabled(true, ['productNameGift']);
      }
      const productNameGift = this.getRule('productNameGift');
      const correlationPro = this.getRule('productReqVos');
      console.log(correlationPro);
      // 产品
      productNameGift.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            productNameGift: e[0].productName,
            productCodeGift: e[0].productCode,
          });
        } else {
          this.setValue({
            productNameGift: null,
            productCodeGift: null,
          });
        }
      };

      correlationPro.on.change = (e) => {
        console.log(e);
        this.setValue({
          productReqVos: e,
        });
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .post('/tpm/tastingOpenScanConfig/getConfigById', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            const productReqVosNew = [];
            if (res.success) {
              console.log(1);
              const { result } = res;
              result.isOpen = result.isOpen.toString();
              result.productRespVos.forEach((item) => {
                productReqVosNew.push({
                  materialName: item.productName,
                  materialCode: item.productCode,
                });
              });
              result.productReqVos = productReqVosNew;
              this.setValue(result);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值

      const productReqVosNew = [];
      formData.productReqVos.forEach((item) => {
        productReqVosNew.push({
          productName: item.materialName,
          productCode: item.materialCode,
        });
      });
      formData.productReqVos = productReqVosNew;
      console.log(formData);
      if (formData) {
        let url = '/tpm/tastingOpenScanConfig/addConfig';
        const params = formData;
        delete params.title1;
        if (this.formConfig.code === 'edit') {
          url = '/tpm/tastingOpenScanConfig/updateConfig';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
