import BasePage from '../../../../../components/table_page';
import Form from './form';
import detailForm from './detail';

export default {
  name: 'bottle_opening_scanning_code',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
    detailForm,
  },
  created() {
    this.getConfigList('tpm_bottle_opening_scanning_code_list');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'add' || val.code === 'edit' || val.code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      }
      if (val.code === 'check_detail') {
        this.formName = 'detailForm';
        this.modalConfig.title = '查看明细';
        this.propsObjInData.row = row;
        this.openFull();
      }
    },
  },
};
