var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-select" },
    [
      _c(
        "div",
        { staticClass: "cost-select" },
        [
          _c(
            "el-select",
            {
              ref: "SelectInputRef",
              attrs: {
                clearable: "",
                multiple: "",
                placeholder: _vm.title,
                value: _vm.inputValue,
                disabled: _vm.disabled,
                readonly: true,
              },
              on: { change: _vm.selectChange, focus: _vm.openModal },
            },
            _vm._l(_vm.value, function (item) {
              return _c("el-option", {
                key: item.materialCode,
                attrs: { label: item.materialName, value: item.materialCode },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }