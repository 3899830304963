<template>
  <div class="custom-select">
    <div class="cost-select">
      <el-select ref="SelectInputRef" @change="selectChange" @focus="openModal" clearable multiple :placeholder="title" :value="inputValue" :disabled="disabled" :readonly="true" >
        <el-option v-for="item in value" :key="item.materialCode" :label="item.materialName" :value="item.materialCode"></el-option>
      </el-select>
    </div>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
// import request from '../../../../utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'BudgetProductList',
  props: {
    title: String,
    value: {
      type: Array,
      default: () => ([]),
    },
    disabled: Boolean,
    getValue: Function,
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // state: '',
    };
  },
  components: {
    SelectConfig,
  },
  computed: {
    inputValue() {
      if (Array.isArray(this.value)) {
        return this.value.map((v) => v.materialCode);
      }
      return [];
    },
  },
  created() {
    // this.getAuthBudget();
  },
  methods: {
    selectChange(e) {
      const newValue = [];
      e.forEach((v) => {
        const item = this.value.find((m) => m.materialCode === v);
        if (item) {
          newValue.push(item);
        }
      });
      this.$emit('input', newValue);
      console.log(e);
    },
    // 新增弹窗
    openModal() {
      const params = { ...this.params };
      console.log(params);
      if (this.disabled || Object.keys(this.params).length === 0) {
        return;
      }

      if (params.idKey) {
        params.data = this.value ? [{
          [`${params.idKey}`]: this.value,
        }] : [];
      } else {
        params.noReset = true;
      }
      this.$refs.SelectInputRef.blur();
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 选择回调
    onGetSelect(val) {
      console.log(val);
      this.$emit('change', val);
    },
    // 清除选择
    clearFn(e) {
      e.stopPropagation();
      this.$emit('change');
    },
  },
};
</script>

<style lang="less" scoped>
.cost-select {
  position: relative;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
</style>
